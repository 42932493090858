function generateCopyright(startYear) {
  handleErrors(startYear)
  const currentYear = new Date().getFullYear()

  if (startYear.toString() === currentYear.toString()) {
    return startYear
  }

  return `${startYear} - ${currentYear}`
}

function handleErrors(startYear) {
  if (typeof startYear == 'undefined')
    throw new Error('You must pass a starting year to generateCopyright')
  if (startYear.toString().trim().length > 5)
    throw new Error(`Starting Year must be a 4 digit year - Too long`)
  if (startYear.toString().trim().length < 4)
    throw new Error(`Starting Year must be a 4 digit year - Too short`)
}

export default generateCopyright
