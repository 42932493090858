import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import loadable from '@loadable/component'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'

import useIntersect from '../../hooks/useIntersect'
import Section from '../Section/Section'
import { themes } from '../../global'
import { NavList } from '../Nav'
import './Footer.scss'
import { Grid, Cell } from '../Grid'
import generateCopyright from '../../utils/generateCopyright'

const Form = loadable(() => import('../Form'))

export const Footer = ({ pathname }) => {
  const theme = themes['black']

  const data = useStaticQuery(graphql`
    query footerData {
      businessInfo: file(relativePath: { eq: "business-info.md" }) {
        childMarkdownRemark {
          frontmatter {
            name
            city
            state
            zip
            email
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "city-sketch.jpg" }) {
        id
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const bgFluid = data.backgroundImage.childImageSharp.gatsbyImageData

  const { name, city, state, zip, displayNumber, email } =
    data.businessInfo.childMarkdownRemark.frontmatter

  const [intersectionRef, entry] = useIntersect({})

  return (
    <>
      <Section
        as='footer'
        hideNav
        fullHeight
        id='contact'
        backgroundImage={bgFluid}
        colorOverlay
        colorTheme='black'>
        <Grid id='iliad__footer--grid'>
          <Cell
            className='iliad__footer--cell'
            hStart={6}
            hSpan={4}
            hStartM={3}
            hSpanM={7}
            hStartS={2}
            hSpanS={12}>
            {entry.isIntersecting && (
              <Form
                colorTheme={theme}
                pathname={pathname}
                displayNumber={displayNumber}
              />
            )}
          </Cell>
          <Cell className='iliad__footer--cell-nav' hSpan={4} hSpanS={12}>
            <nav className='iliad__footer--nav'>
              <ul>
                <NavList
                  itemColor={theme.navColor}
                  itemHoverColor={theme.navHoverColor}
                  pathname={pathname}
                  footer
                />
              </ul>
            </nav>
          </Cell>
        </Grid>
        <div
          ref={intersectionRef}
          className={`iliad__footer--info ${
            entry.isIntersecting ? 'animation--footer' : ''
          }`}
          // style={props}
        >
          <div className='iliad__footer--social-media'>
            <a
              aria-label='Facebook Link'
              href='https://www.facebook.com/iliadgrow'>
              <FaFacebookSquare />
            </a>
            <a
              aria-label='Instagram Link'
              href='https://www.instagram.com/iliadgrow/'>
              <FaInstagram />
            </a>
          </div>
          <address>
            <span>{name}</span>
            <div className='iliad__address--flex'>
              <span className='iliad__address--block'>
                {/* <span>{address}</span><br/> */}
                <span>
                  {city}, {state} {zip}
                </span>
                <br />
              </span>
              <span className='iliad__contact--block'>
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            </div>
          </address>
          <span className='iliad__footer--copyright'>
            ©{generateCopyright(2020)} RGR Industries Inc. All rights reserved.
            <br />
            <Link to='/terms-of-service/'>Terms of Service</Link>
          </span>
        </div>
      </Section>
    </>
  )
}

// export default Footer
