import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = React.memo(
  ({
    title = '',
    description = '',
    ogImage = {},
    meta = [],
    article = false,
    pathname = '',
    noIndex = false,
  }) => {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `
    )

    return (
      <Helmet
        title={title}
        meta={[
          {
            name: `description`,
            content: description || site.siteMetadata.description,
          },
          {
            name: 'robots',
            content: noIndex ? 'noindex, follow' : 'index, follow',
          },
          {
            property: 'og:locale',
            content: 'en_US',
          },
          {
            property: `og:title`,
            content: title || site.siteMetadata.title,
          },
          {
            property: `og:description`,
            content: description || site.siteMetadata.description,
          },
          {
            property: `og:type`,
            content: `${article ? 'article' : 'website'}`,
          },
          {
            property: 'og:url',
            content: `https://www.iliadgrow.com${pathname}`,
          },
          {
            property: 'og:site_name',
            content: site.siteMetadata.title,
          },
          {
            property: 'og:image',
            content: ogImage ? `http://www.iliadgrow.com${ogImage.src}` : null,
          },
          {
            property: 'og:image:secure_url',
            content: ogImage ? `https://www.iliadgrow.com${ogImage.src}` : null,
          },
          {
            property: 'og:image:width',
            content: ogImage ? ogImage.width : null,
          },
          {
            property: 'og:image:height',
            content: ogImage ? ogImage.height : null,
          },
          {
            name: 'twitter:image',
            content: ogImage ? `https://www.iliadgrow.com${ogImage.src}` : null,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title || site.siteMetadata.title,
          },
          {
            name: `twitter:description`,
            content: description || site.siteMetadata.description,
          },
        ].concat(meta)}
      />
    )
  }
)

export default Seo
