import React from 'react'
import loadable from '@loadable/component'
import { MenuContextProvider } from '../context/MenuContext'
import useLocalStorage from '../hooks/useLocalStorage'
import { Footer } from 'components/Footer'
import '../scss/resets.scss'
import '../scss/app.scss'
import SnapScrollContainer from 'components/SnapScrollContainer'

const AgePrompt = loadable(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import('../components/AgePrompt')), 2000)
    ),
  {
    fallback: <div />,
    ssr: false,
  }
)

const PageLayout = ({ children, pathname, scrollRestoration }) => {
  const [userOfAge, setUserOfAge] = useLocalStorage('userOfAge', false)
  return (
    <MenuContextProvider>
      <SnapScrollContainer {...scrollRestoration}>
        {children}
        <Footer pathname={pathname} />
        {!userOfAge && (
          <AgePrompt
            userOfAge={userOfAge}
            setUserOfAge={() => setUserOfAge(true)}
          />
        )}
      </SnapScrollContainer>
    </MenuContextProvider>
  )
}

export default PageLayout
