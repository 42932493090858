import React from 'react'
import { gridStyle } from './Grid.module.scss'

export const Grid = ({ children, className, ...rest }) => {
  const classList = [gridStyle, className].join(' ')

  return (
    <div className={classList} {...rest}>
      {children}
    </div>
  )
}
