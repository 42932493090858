import { useState, useRef, useEffect } from 'react';

const useIntersect = ({ root = null, rootMargin, threshold = 0}) => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(null);

  useEffect(
    () => {
      if (observer.current) observer.current.disconnect();

      // Makes sure that we're in a browser, so that there are no SSR build errors
      if (typeof window !== 'undefined') {
        observer.current = new window.IntersectionObserver(
          ([entry]) => updateEntry(entry),
          {
            root,
            rootMargin,
            threshold,
          }
        );

        const { current: currentObserver } = observer;

        if (node) currentObserver.observe(node);

        return () => currentObserver.disconnect();
      }

    },
    [node, root, rootMargin, threshold]
  );

  return [setNode, entry];
};

export default useIntersect;
