import React from "react"
import * as styles from "./SnapScrollContainer.module.scss"

// TODO: move styles for snap scroll stuff in here instead of the global app style sheet.

const SnapScrollContainer = ({ children }) => {
  return (
    <div className={styles.main}>
      <div className={styles.snapContainer}>{children}</div>
    </div>
  )
}

export default SnapScrollContainer
