import React, { useMemo } from 'react'
import loadable from '@loadable/component'
import Nav from '../Nav'
import { themes } from '../../global'
import './Section.scss'

const SectionType = loadable(() => import('./SectionType'))
const ContentGrid = loadable(() => import('./ContentGrid'))
const SectionContent = loadable(() => import('./SectionContent'))
const SectionChildren = loadable(() => import('./SectionChildren'))
const VectorImage = loadable(() => import('../VectorImage/VectorImage'))

const Section = ({
  as = 'section',
  id,
  index,
  svg,
  fullHeight = false,
  lockNav,
  hideNav = false,
  colorTheme,
  backgroundImage,
  content,
  ctaButton = {},
  colorOverlay = false,
  pathname,
  children,
  image,
}) => {
  // Performance to make sure that theme is memoized to prevent Nav and Logo rerenders
  const theme = useMemo(() => themes[colorTheme], [colorTheme])

  return (
    <div
      id={id}
      as={as}
      className={`iliad__section ${
        fullHeight ? 'iliad__section--fullheight' : ''
      } iliad__snap--child iliad__section--theme-${colorTheme}`}
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.bodyColor,
      }}>
      <div className='iliad__section--clip-parent'>
        <Nav
          {...theme}
          lockNav={lockNav}
          logoOnly={hideNav}
          pathname={pathname}
          index={index}
        />
      </div>
      <SectionType
        pathname={pathname}
        as={as}
        backgroundImage={backgroundImage}
        theme={theme}
        fadeIn={false}
        children={children}>
        {colorOverlay && (
          <div
            className='iliad__section--overlay'
            style={{
              backgroundColor: theme.overlayColor,
            }}
          />
        )}
        {image}
        {content && (
          <ContentGrid posX={content.posX} posY={content.posY}>
            <SectionContent
              h1Tag={as === 'header' ? true : false}
              content={content}
              ctaButton={ctaButton}
              nextSection={
                typeof index !== 'undefined'
                  ? `#page-section-${index + 1}`
                  : null
              }
              colorTheme={theme}
            />
          </ContentGrid>
        )}
        {svg && (
          <div
            className={`
            iliad__section--svg-container
            ${svg.x ? `iliad__section--svg-posX-${svg.x}` : ''}
            ${svg.y ? `iliad__section--svg-posY-${svg.y}` : ''}
            ${svg.y ? `iliad__section--svg-size-${svg.size}` : ''}
          `}>
            <VectorImage art={svg.art} />
          </div>
        )}
        {children && <SectionChildren>{children}</SectionChildren>}
      </SectionType>
    </div>
  )
}

export default Section
