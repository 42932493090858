import React, { useState, useCallback } from "react"

const MenuContext = React.createContext(false);

const MenuContextProvider = ({children}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuOpen(m => !m);
  }, [setMenuOpen]);
  const value = {
    menuOpen,
    toggleMenu,
  };
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

export { MenuContext, MenuContextProvider };
