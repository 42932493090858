function hexToRGBA(h, a) {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgba("+ +r + "," + +g + "," + +b + ", " + a + ")";
}

const colors = {
  white: '#fafafa',
  black: '#2b2b2b',
  lace: '#F4F0E1',
  charcoal: '#555358',
  red: '#FE5F55',
  green: '#38433B',
  lightGreen: '#69b38f',
  blue: '#3D5A6C',
  lightBlue: '#DCEDFF',
  purple: '#514E6A',
  lightPurple: '#ABA9BF',
  yellow: '#F3DE8A',
  gunmetal: '#333745',
  brown: '#38302E',
};

const themes = {
  lace: {
    backgroundColor: hexToRGBA(colors.lace, 1),
    overlayColor: hexToRGBA(colors.lace, 0.65),
    navColor: hexToRGBA(colors.gunmetal, 0.92),
    navHover: colors.red,
    bodyColor: hexToRGBA(colors.gunmetal, 0.92),
    strongColor: hexToRGBA(colors.red, 0.92),
    buttonColor: hexToRGBA(colors.gunmetal, 0.75),
    buttonBorderColor: hexToRGBA(colors.gunmetal, 0.9),
    buttonHoverColor: colors.gunmetal,
    buttonBackgroundColor: hexToRGBA(colors.gunmetal, 0),
    buttonHoverBackgroundColor: hexToRGBA(colors.gunmetal, 0),
    buttonBorderHoverColor: colors.red,
    logoStyle: 'light'
  },
  gunmetal: {
    backgroundColor: colors.gunmetal,
    overlayColor: hexToRGBA(colors.gunmetal, 0.65),
    navColor: colors.white,
    navHover: colors.red,
    bodyColor: hexToRGBA(colors.lace, 0.95),
    strongColor: hexToRGBA(colors.red, 0.92),
    buttonColor: hexToRGBA(colors.white, 0.75),
    buttonBorderColor: hexToRGBA(colors.white, 0.9),
    buttonHoverColor: colors.white,
    buttonBorderHoverColor: colors.red,
    buttonBackgroundColor: hexToRGBA(colors.black, 0.25),
    buttonHoverBackgroundColor: hexToRGBA(colors.black, 0),
    logoStyle: 'dark',
  },
  green: {
    backgroundColor: colors.green,
    overlayColor: hexToRGBA(colors.green, 0.65),
    navColor: colors.white,
    navHover: colors.lightGreen,
    bodyColor: hexToRGBA(colors.white, 0.85),
    strongColor: hexToRGBA(colors.lightGreen, 1),
    buttonColor: hexToRGBA(colors.white, 0.75),
    buttonBorderColor: hexToRGBA(colors.white, 0.9),
    buttonHoverColor: colors.white,
    buttonBorderHoverColor: colors.lightGreen,
    buttonBackgroundColor: hexToRGBA(colors.black, 0.14),
    buttonHoverBackgroundColor: hexToRGBA(colors.black, 0),
    logoStyle: 'mono',
  },
  red: {
    backgroundColor: colors.red,
    overlayColor: hexToRGBA(colors.red, 0.62),
    navColor: colors.white,
    navHover: colors.lightBlue,
    bodyColor: hexToRGBA(colors.white, 0.95),
    strongColor: hexToRGBA(colors.white, 1),
    buttonColor: hexToRGBA(colors.white, 0.75),
    buttonBorderColor: hexToRGBA(colors.white, 0.9),
    buttonHoverColor: colors.white,
    buttonBorderHoverColor: colors.gunmetal,
    buttonBackgroundColor: hexToRGBA(colors.black, 0.12),
    buttonHoverBackgroundColor: hexToRGBA(colors.black, 0),
    logoStyle: 'mono',
  },
  black: {
    backgroundColor: hexToRGBA(colors.black, 1),
    overlayColor: hexToRGBA(colors.black, 0.75),
    navColor: colors.white,
    navHover: hexToRGBA(colors.lightBlue, 0.76),
    bodyColor: colors.white,
    strongColor: colors.red,
    buttonColor: hexToRGBA(colors.white, 0.75),
    buttonBorderColor: hexToRGBA(colors.white, 0.9),
    buttonHoverColor: colors.white,
    buttonBorderHoverColor: colors.red,
    buttonBackgroundColor: hexToRGBA(colors.white, 0.015),
    buttonHoverBackgroundColor: hexToRGBA(colors.white, 0),
    logoStyle: 'dark',
  },
  brown: {
    backgroundColor: colors.brown,
    overlayColor: hexToRGBA(colors.brown, 0.65),
    navColor: colors.lace,
    navHover: colors.lightBlue,
    bodyColor: colors.lace,
    strongColor: colors.green,
    buttonColor: hexToRGBA(colors.lace, 0.85),
    buttonBorderColor: hexToRGBA(colors.lace, 0.87),
    buttonHoverColor: colors.lace,
    buttonBorderHoverColor: colors.lightBlue,
    buttonBackgroundColor: hexToRGBA(colors.charcoal, 0.03),
    buttonHoverBackgroundColor: hexToRGBA(colors.charcoal, 0),
    logoStyle: 'mono',
  },
  purple: {
    backgroundColor: colors.purple,
    overlayColor: hexToRGBA(colors.purple, 0.65),
    navColor: colors.white,
    navHover: colors.yellow,
    bodyColor: colors.white,
    strongColor: colors.yellow,
    buttonColor: hexToRGBA(colors.white, 0.86),
    buttonBorderColor: hexToRGBA(colors.white, 0.9),
    buttonHoverColor: colors.white,
    buttonBorderHoverColor: colors.yellow,
    buttonBackgroundColor: hexToRGBA(colors.charcoal, 0.012),
    buttonHoverBackgroundColor: hexToRGBA(colors.charcoal, 0),
    logoStyle: 'mono',
  },
  white: {
    backgroundColor: hexToRGBA(colors.white, 1),
    overlayColor: hexToRGBA(colors.white, 0.65),
    navColor: hexToRGBA(colors.gunmetal, 1),
    navHover: colors.red,
    bodyColor: colors.gunmetal,
    strongColor: hexToRGBA(colors.red, 0.92),
    buttonColor: hexToRGBA(colors.gunmetal, 0.82),
    buttonBorderColor: hexToRGBA(colors.gunmetal, 0.9),
    buttonHoverColor: colors.gunmetal,
    buttonBorderHoverColor: colors.red,
    buttonBackgroundColor: hexToRGBA(colors.gunmetal, 0.015),
    buttonHoverBackgroundColor: hexToRGBA(colors.gunmetal, 0),
    logoStyle: 'light'
  },

};

const logoThemes = {
  dark: {
    helmet: colors.red,
    iliad: colors.white,
    epic: hexToRGBA(colors.white, 0.92),
    grow: colors.red,
    leaves: hexToRGBA(colors.white, 0.66),
  },
  light: {
    helmet: colors.red,
    iliad: colors.gunmetal,
    epic: hexToRGBA(colors.gunmetal, 0.92),
    grow: colors.red,
    leaves: hexToRGBA(colors.gunmetal, 0.9),
  },
  mono: {
    helmet: hexToRGBA(colors.white, 0.75),
    iliad: colors.white,
    epic: hexToRGBA(colors.white, 0.92),
    grow: hexToRGBA(colors.white, 0.66),
    leaves: hexToRGBA(colors.white, 0.66),
  }
}

const breakpoints = {
  sm: 579,
  md: 1211,
  lg: 1600,
  xl: 1920,
}

export { colors, breakpoints, themes, logoThemes };
